<template>
    <button
        :class="classNames"
        @click="click"
        :disabled="disabled || !!loading"
        :title="disabled ? disabledMessage : undefined"
        :type="submit ? 'submit' : 'button'"
    >
        <div v-if="loading" class="button-loading-outer">
            <div class="spinner">Loading...</div>
        </div>
        <div class="button-inner">
            <span v-if="icon" :class="'icon icon-' + icon"></span>
            <div v-if="progress > 0" class="button-progress" :style="{ width: progress + '%' }"></div>
            <span class="button-label">
                <slot></slot>
            </span>
        </div>
    </button>
</template>

<script>
export default {
    name: "BaseButton",
    props: {
        submit: {
            type: Boolean,
            default: false,
        },
        type: {
            type: String,
            default: "",
        },
        color: {
            type: String,
            default: "primary",
        },
        progress: {
            type: Number,
            default: 0,
        },
        icon: {
            type: String,
            default: "",
        },
        href: {
            type: String,
            default: "",
        },
        target: {
            type: String,
            default: "",
        },
        size: {
            type: String,
            default: "",
        },
        loading: {
            type: [Boolean, Number],
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        disabledMessage: {
            type: String,
            default: "",
        },
    },
    computed: {
        classNames() {
            let classes = ["button"]
            classes.push("button-" + this.type)
            classes.push("button-" + this.color)
            if (this.size) {
                classes.push("button-size-" + this.size)
            }
            if (this.loading) {
                classes.push("button-loading")
            }

            return classes.join(" ")
        },
    },
    methods: {
        click() {
            if (this.href) {
                if (this.target == "_blank") {
                    window.open(this.href)
                } else if (this.target === "_direct") {
                    window.location.href = this.href
                } else {
                    this.$inertia.visit(this.href)
                }
            }

            this.$emit("click", ...arguments)
        },
    },
}
</script>
